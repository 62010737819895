<script>
import socialsService from '@/services/socials.service'
import Social from '@/data/models/Social'
import EditLayout from '@/layout/EditLayout'

export default {
  name: 'SingleSocial',
  components: {
    EditLayout
  },
  data() {
    return {
      items: [],
      item: new Social,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    isNew() {
      return this.id === 'new'
    },
  },  
  mounted() {
    this.isNew && this._getAll()
    this._get()
  },
  methods: {
    _getAll() {
      socialsService.getAll()
        .then(res => {
          this.items = res.data
          this.item.position = res.data.length + 1
        })
    },
    _get() {
      socialsService.getById({ id: this.id })
        .then(res => {
          this.item = res.data
        })
    },
    create() {
      socialsService.create({ data: this.item })
        .then(response => {
          this.$notify({
            message:  this.$t('m.itemSuccessfullyAdded', { item: this.$t('socialMedia') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
          this.$router.push({ name: 'social' })
        })
    },
    edit() {
      socialsService.edit({ data: this.item })
        .then(_res => {
          this.$notify({
            message: this.$t('m.itemSuccessfullySaved', { item: this.$t('socialMedia') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
        })
    },
  }
}
</script>

<template>
  <EditLayout 
    :item="item"
    :submit-button-text="isNew ? $t('create') : $t('save')"
    :supports-editor="false"
    @submit="isNew ? create() : edit()"
  >
    <div slot="middle">
      <BaseInput
        v-model="item.icon"
        :label="$tc('icon')"
      />
      <BaseInput
        v-model="item.link"
        :label="$tc('link')"
      />
    </div>
    <div slot="sidebar-middle">
      <BaseInput
        v-model="item.position"
        :label="$t('position')"
        disabled
      />
      <label>{{ $t('indicativeIcon') }}</label>
      <div>
        <BaseIcon
          icons-group="fab"
          :icon="`fa-${item.icon}`"
        />
      </div>
    </div>
  </EditLayout>
</template>