/* eslint-disable camelcase */
export default class Social {
  icon;
  link;
  name;
  position;

  constructor() {
    this.icon = '';
    this.link = '';
    this.name = '';
    this.position = 999;
  }
}
